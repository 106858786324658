import { reactLocalStorage } from 'reactjs-localstorage';

const useUserFromLocalStorage = () => {
  const storedUser = reactLocalStorage.get('user');
  
  const user = JSON.parse(storedUser)

  return user
};

const updateLocalStorageUSerData = (updatedUserData) => {
  const newUserData = JSON.stringify(updatedUserData)
  reactLocalStorage.set('user', newUserData)

  return useUserFromLocalStorage()
}

export {
  useUserFromLocalStorage,
  updateLocalStorageUSerData
}


