import { useNavigate, useLocation } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';

const isTokenExpired = () => {
  const token = reactLocalStorage.get('token');
  if (!token) {
    return null;
  }

  try {
    const { exp } = jwtDecode(token);
    return exp * 1000 < Date.now();
  } catch (error) {
    return true;
  }
};

const clearUserData = () => {
  reactLocalStorage.remove('token');
  reactLocalStorage.remove('user');
};

const useAuthRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    const tokenExpired = isTokenExpired();
    console.log(tokenExpired)


    if (tokenExpired === null) {
      navigate('/login', { replace: true });
      return;
    } else if (tokenExpired) {
      navigate('/login', {
        state: { message: 'Sessão expirada, por favor faça login novamente.' },
        replace: true,
      });
      clearUserData();
    }

  }, [location.pathname, navigate]);
};

export default useAuthRedirect;
