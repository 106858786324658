import { useRoutes } from 'react-router-dom';
import ComponentsRoutes from './ComponentsRoutes';
import MainRoutes from './MainRoutes';
import useAuthRedirect from 'services/checkTokenExpiration';


export default function ThemeRoutes() {
  useAuthRedirect()

  return useRoutes([MainRoutes, ComponentsRoutes]);
}
